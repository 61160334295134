import React from "react";
import {Link} from "gatsby";
import BotonModalContacto from "../../Modales/BotonModalContacto";

const DosBotones = () => {
	return (
		<section className="filadoscol alaizquierda" id="wrapper-dos-botones">
			<div className="doscolumnas">
				<BotonModalContacto />
			</div>
			<div className="doscolumnas">
				<Link to="/#servicios">
					<button className="btn-blanco-border">Ver servicios</button>
				</Link>
			</div>
		</section>
	);
};

export default DosBotones;
