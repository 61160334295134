import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {useLocation} from "@reach/router";
import {useStaticQuery, graphql} from "gatsby";

const seoQuery = graphql`
	{
		site {
			siteMetadata {
				author
				defaultDescription: description
				defaultTitle: title
				titleTemplate
				siteUrl: url
				image
				twitterUsername
				description
			}
		}
	}
`;

const SEOComponent = ({
	title,
	singleDescription,
	featuredImage,
	singleSchema,
	singleKeywords,
	type,
	canonical,
	robots,
}) => {
	const {site} = useStaticQuery(seoQuery);
	const {pathname} = useLocation();

	// Desconstruyendo
	const {
		defaultTitle,
		titleTemplate,
		defaultDescription,
		siteUrl,
		image,
		twitterUsername,
	} = site.siteMetadata;

	const seoProps = {
		title: title || defaultTitle,
		description: singleDescription || defaultDescription,
		image: featuredImage || image,
		schema: singleSchema,
		url: `${siteUrl}${pathname}`,
		type: type,
		keywords: singleKeywords,
		isCanonical: canonical,
		robots: robots,
	};

	// Filtros de JSON-LD

	let schemaFilter;
	if (singleSchema === "Article") {
		schemaFilter = {
			"@context": "https://schema.org",
			"@type": "BlogPosting",
			mainEntityOfPage: {
				"@type": "WebPage",
				"@id": "",
			},
			headline: "",
			image: "",
			author: {
				"@type": "",
				name: "",
			},
			publisher: {
				"@type": "Person", // Person or Organization
				name: "",
				logo: {
					"@type": "ImageObject",
					url: "",
				},
			},
			datePublished: "",
			dateModified: "",
		};
	} else if (singleSchema === "FAQ") {
		schemaFilter = {
			"@context": "https://schema.org",
			"@type": "BlogPosting",
			mainEntityOfPage: {
				"@type": "WebPage",
				"@id": "", // URL del blog post
			},
			headline: "",
			image: "",
			author: {
				"@type": "", // Person or Organization
				name: "",
			},
			publisher: {
				"@type": "Person", // Person or Organization
				name: "",
				logo: {
					"@type": "ImageObject",
					url: "",
				},
			},
			datePublished: "", // YY MM DD
			dateModified: "", // YY MM DD
		};
	} else if (singleSchema === "HowTo") {
		schemaFilter = {
			"@context": "https://schema.org/",
			"@type": "HowTo",
			name: "",
			description: "",
			image: "",
			totalTime: "",
			estimatedCost: {
				"@type": "MonetaryAmount",
				currency: "",
				value: "",
			},
			step: [
				{
					"@type": "HowToStep",
					text: "",
					image: "",
					name: "",
					url: "",
				},
				{
					"@type": "HowToStep",
					text: "",
				},
			],
		};
	} else {
		schemaFilter = {
			"@context": "https://schema.org/",
			"@type": "Person",
			name: "",
			url: "",
			image: "",
			sameAs: ["https://", "https://", "https://"], //cada item es el link a una red social
			jobTitle: "",
			worksFor: {
				"@type": "Organization",
				name: "",
			},
		};
	}

	return (
		<Helmet
			title={`${title}`}
			titleTemplate={titleTemplate}
			htmlAttributes={{lang: "es"}}
		>
			<meta charSet="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			{seoProps.robots && <meta name="robots" content={seoProps.robots} />}
			{seoProps.keywords && (
				<meta name="keywords" content={seoProps.keywords} />
			)}
			{seoProps.description && (
				<meta name="description" content={seoProps.description} />
			)}
			<meta name="image" content={seoProps.image} />

			{seoProps.isCanonical && (
				<link rel="canonical" href={seoProps.isCanonical} />
			)}

			{seoProps.url && <meta property="og:url" content={seoProps.url} />}

			{(type ? true : null) && <meta property="og:type" content="article" />}

			{schemaFilter && <script type="application/ld+json">{``}</script>}

			{seoProps.title && <meta property="og:title" content={seoProps.title} />}

			{seoProps.description && (
				<meta property="og:description" content={seoProps.description} />
			)}

			{seoProps.image && <meta property="og:image" content={seoProps.image} />}

			<meta name="twitter:card" content="summary_large_image" />

			{twitterUsername && (
				<meta name="twitter:creator" content={twitterUsername} />
			)}

			{seoProps.title && <meta name="twitter:title" content={seoProps.title} />}

			{seoProps.description && (
				<meta name="twitter:description" content={seoProps.description} />
			)}

			{seoProps.image && <meta name="twitter:image" content={seoProps.image} />}
		</Helmet>
	);
};

export default SEOComponent;

SEOComponent.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	type: PropTypes.bool,
};
SEOComponent.defaultProps = {
	title: null,
	description: null,
	type: "Article",
	singleDescription: null,
	singleKeywords: null,
	singleSchema: null,
	featuredImage: null,
	keywords: null,
	isCanonical: null,
	robots: "index, follow",
};
