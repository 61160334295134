import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import "./servicios.css";

const ServiciosArchive = () => {
	return (
		<section className="filaunacol paddingtopsdefault" id="servicios">
			<div className="unacolumna">
				<h2 className="centrado">
					Nuestros <span className="underlined">servicios</span>
				</h2>
				<section className="filatrescol paddingtopdefault-small row-col-3">
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Capacitaciones.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Capacitaciones</h4>
									<p className="peq extracto">
										Para el desarrollo para la empresarialidad. Manejar una
										empresa no es tarea fácil, por eso deseamos ofrecerte
										capacitaciones que te permitan contar con las herramientas
										principales para hacer que tu empresa tenga estructura.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Asesorias.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Asesorías empresariales</h4>
									<p className="peq extracto">
										Los emprendimientos son como los bebés, requieren de mucha
										atención y mantenimiento para su crecimiento. Recibir
										guianza de expertos es una buena manera de cuidar a tu bebe
										y darle lo mejor.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Consultoria de proyectos.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Consultoría de proyectos</h4>
									<p className="peq extracto">
										Haz que tus proyectos lleguen al siguiente nivel, con
										lineamientos y directrices de profesionales que te ayudarán
										a tener una visión más amplia de lo que necesitas y de las
										soluciones que puedes implementar.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="filatrescol paddingtopdefault-small row-col-3">
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Plan de comunicaciones.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Plan de Comunicaciones</h4>
									<p className="peq extracto">
										No se trata solo del mensaje, también del mensajero, y las
										formas son importantes. Por eso un buen plan de comunicación
										puede marcar la diferencia entre las ideas que rápido se
										olvidan y aquellas que perduran en el tiempo.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Asesoria comercial.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Asesoría Comercial</h4>
									<p className="peq extracto">
										Lo que muestras y cómo lo muestras son factores de suma
										importancia para determinar el éxito o el fracaso ventas,
										por lo que tener la sabiduría y la experiencia de tu lado
										jugará un papel vital si deseas ser en un pilar de tu
										industria.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Asesoria tactica.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Asesoría Táctica</h4>
									<p className="peq extracto">
										Ya sabes lo que quieres, para cuando lo quieres y cómo lo
										quieres, solo necesitas ese mapa que te muestre los pasos a
										seguir para alcanzar lo que tanto has envisionado y soñado
										lograr para ti y tu negocio.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="filatrescol paddingtopdefault-small row-col-3">
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Planificacion estrategica.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Planificación Estratégica y Operativa Empresarial</h4>
									<p className="peq extracto">
										El día a día del negocio se presenta a sí mismo con
										realidades distintas siempre, por lo que, tener una
										planificación, una directrices claras y, excelentes
										mecanismos de control, posicionarán la empresa por encima de
										sus competidores.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Estrategia de comunicacion.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Estrategia de Comunicación Digital</h4>
									<p className="peq extracto">
										Los tiempos han cambiado y las vías de comunicación también,
										haciendo obligatoria la reinvención de las formas en las que
										se alcanzan prospectos y se convierten en fieles clientes
										utilizando medios digitales.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Voz para.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Voz para materiales publicitarios</h4>
									<p className="peq extracto">
										La cadencia, la entonación y la emoción en la voz determina
										en gran medida qué tanto un anuncio conectará con la
										audiencia. En DREAMERS nos aseguramos de que tengas las voz
										que necesitas para el éxito que estás buscando.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="filatrescol paddingtopdefault-small row-col-3">
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Coordinacion de eventos.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Coordinación de Eventos</h4>
									<p className="peq extracto">
										La coordinación de un evento no es una tarea fácil. Requiere
										de tiempo y atención a los detalles. Planificar, organizar y
										supervisar los espacios y detalles son aspectos importantes
										que deben cuidarse para que su evento sea un éxito.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Relaciones.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Relaciones públicas</h4>
									<p className="peq extracto">
										Creemos que todas las empresas deben tener y potenciar su
										voz. Mediante las relaciones públicas, ayudamos a que las
										organizaciones creen y distribuyan sus procesos de
										comunicación de forma estratégica y efectiva.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Maestria.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Maestría de ceremonias</h4>
									<p className="peq extracto">
										En tiempos remotos, contar con un buen maestro de ceremonias
										en tus eventos, podría determinar qué tan exitoso o no sea
										el mismo. A través de este servicio nos aseguramos de que la
										clase, gracia y buen ambiente, nunca se pierdan.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="filatrescol paddingtopdefault-small row-col-3">
					<div className="card-servicios-container col-3">
						<div className="one-card-servicios">
							<div className="card-servicios">
								<div className="card-servicios-header">
									<StaticImage
										src="../../assets/images/Servicios/Organizacion eventos.jpg"
										alt=""
									/>
								</div>
								<div className="card-servicios-content">
									<h4>Organización de Eventos</h4>
									<p className="peq extracto">
										Sabemos que puede ser difícil organizar un evento sin la
										ayuda de un profesional experimentado, por eso estamos para
										ti. Deseamos que conectes con tus clientes, empleados,
										socios e invitados de forma acertada a través de eventos
										profesionales.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
	);
};

export default ServiciosArchive;
