import React from "react";
import Faq from "react-faq-component";

const data = {
	rows: [
		{
			title:
				"Independientemente de la edad, ¿Puedo ser un “Dreamer” y soñar con tener mi propia empresa?",
			content: `Por mucho que no nos guste esta es una pregunta que bastantes personas se hacen, sobre todo los jóvenes, y es una pena debido a que los sueños y empresas son alcanzables para todos aquellos que se lo propongan. Así que si, no importa tu edad, puedes ser un <strong>Dreamer</strong>.`,
		},
		{
			title:
				"¿En qué etapa de mi emprendimiento o empresa debo buscar asesoría?",
			content: `Excelente pregunta, la verdad es que no hay una respuesta correcta, cada emprendimiento es distinto, ahora bien, nosotros creemos que mientras más temprano se haga mejor. Contar con la experiencia y sabiduría de un asesor en las primeras etapas (las más difíciles)  de un negocio puede ser el elemento diferenciador entre el éxito y el fracaso. `,
		},
		{
			title: "¿Puedo soñar con tener una gran empresa?",
			content: `Como diría el refrán popular, “La pregunta ofende” pues claro que puedes y deberías soñar con tener una gran empresa, eso sí, siempre recuerda que el éxito y la grandeza lucen distintos para cada quien, por lo que nunca debes definir tu grandeza por lo que otros hacen.`,
		},
		{
			title: "¿Puede una MIPYME crecer?",
			content: `Claro que una empresa Mipyme puede crecer, lo único que necesitas para ello es saber dos pilares fundamentales:<ul>
				<li>Que tan grandes son tus sueños</li>
				<li>Qué tan dispuesto(a) a luchar por ellos estas.</li>
			</ul>
`,
		},
		{
			title: "¿Cómo me conecto de manera más eficiente con mis clientes?",
			content: `Existen múltiples formas de responder a esta pregunta, pero nosotros particularmente creemos que la mejor manera de conectar con tus clientes es a través de ser intencional en conocer y saber con exactitud qué es eso que más que desear, necesitan tus clientes. `,
		},
	],
};

const styles = {
	bgColor: "transparent",
	titleTextColor: "black",
	rowTitleColor: "black",
	rowContentColor: "black",
	arrowColor: "black",
};

const config = {
	animate: true,
	arrowIcon: "+",
	tabFocus: true,
};

const FAQAcordeon = () => {
	return (
		<section className="filaunacol paddingtopsdefault">
			<div className="unacolumna pagina-peq paddingtopsdefault">
				<h2 className="centrado paddingbottomdefault">
					Preguntas <span className="underlined">frecuentes</span>
				</h2>
				<Faq data={data} styles={styles} config={config} />
			</div>
		</section>
	);
};

export default FAQAcordeon;
