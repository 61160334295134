import React from "react";
import BotonModalContacto from "../../Modales/BotonModalContacto";
import {StaticImage} from "gatsby-plugin-image";

const CTAFooter = () => {
	return (
		<section className="anchocompleto fondogrisf8  paddingtopsdefault-extra">
			<div className="filadoscol ancho-pagina">
				<div className="doscolumnas pagina-peq">
					<h2>
						¿Cuándo te <span className="underlined">gustaría empezar?</span>
					</h2>
					<p>Da click en el botón para empezar.</p>
					<BotonModalContacto />
				</div>
				<div className="doscolumnas">
					<StaticImage
						className="img-s"
						src="../../../assets/images/homecta.jpg"
					/>
				</div>
			</div>
		</section>
	);
};

export default CTAFooter;
