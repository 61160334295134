import React from "react";
import Layout from "../components/Layouts/Layout";
import DosBotones from "../components/Botones/DosBotones/DosBotones";
import LogosClientes from "../components/Testimonials/LogosClientes";
import ServiciosArchive from "../components/Servicios/ServiciosArchive";
import FAQAcordeon from "../components/FAQAcordeon/FAQAcordeon";
import CTAFooter from "../components/Layouts/CTAs/CTAFooter";
import {StaticImage} from "gatsby-plugin-image";
import {
	MdLightbulbOutline,
	MdVerified,
	MdQueryStats,
	MdOutlineLensBlur,
} from "react-icons/md";
import SEOComponent from "../components/SEO/SEOComponent";

const HomePage = () => {
	return (
		<Layout>
			<SEOComponent
				title="Dreamers Business and Training"
				singleSchema="Article"
			/>
			<section className="hero filadoscol margintopdefault">
				<div className="doscolumnas marginrightdefault ancho-pagina">
					<h1>Dreamers</h1>
					<h2>
						<span className="underlined">Fomentando el desarrollo</span>
					</h2>
					<p>
						Como empresa creemos en el desarrollo y crecimiento integral de las
						ideas y organizaciones
					</p>
					<DosBotones />
				</div>
				<div className="doscolumnas">
					<StaticImage
						className="img-s"
						src="../assets/images/homehero.jpg"
						// width={800}
					/>
				</div>
			</section>
			<section className="filaunacol pagina-peq paddingtopsdefault">
				<div className="unacolumna centrado">
					<p className="centrado">Nuestros aliados</p>
					<LogosClientes />
				</div>
			</section>
			<section className="filadoscol paddingtopsdefault" id="about">
				<div className="doscolumnas">
					<h2>
						¿Quiénes <span className="underlined">somos?</span>
					</h2>
					<p>
						Somos una empresa que tiene como visión ser un referente de
						desarrollo para la empresarialidad, servicios de comunicaciones
						corporativas y fomento de proyectos multisectoriales.
					</p>
					<p>
						Nuestra misión Suplir servicios de capacitación, asesoría,
						comunicaciones y consultoría para emprendedores, empresarios de las
						MIPYMES y organizaciones comprometidas con el desarrollo de
						proyectos multisectoriales, con un equipo de alto impacto
						multidisciplinario para el logro de metas estratégicas.
					</p>
					<p>
						Nuestros valores son nuestro fundamento y, en ellos nos anclamos
						para llevar no solamente un buen servicio, sino también para y más
						importante, impactar vidas. Por eso, el cumplimiento, la Lealtad, la
						responsabilidad, la modernidad, y el dinamismo nos identifican.
					</p>
				</div>
				<div className="doscolumnas paddingleftdefault">
					<section className="row-col-2">
						<div className="card-servicios-container col-2">
							<div className="card-servicios">
								<MdVerified />
								<p className="grande negrita">Capacitación</p>
							</div>
						</div>
						<div className="card-servicios-container col-2 margintopdefault-small">
							<div className="card-servicios">
								<MdLightbulbOutline />
								<p className="grande negrita">Asesorías</p>
							</div>
						</div>
					</section>
					<section className="row-col-2">
						<div className="card-servicios-container col-2">
							<div className="one-card-servicios">
								<div className="card-servicios">
									<div className="card-servicios-header">
										<MdQueryStats />
										<p className="grande negrita">Consultoría</p>
									</div>
								</div>
							</div>
						</div>
						<div className="card-servicios-container col-2 margintopdefault-small">
							<div className="one-card-servicios">
								<div className="card-servicios">
									<MdOutlineLensBlur />
									<p className="grande negrita">Eventos</p>
								</div>
							</div>
						</div>
					</section>
				</div>
			</section>
			<ServiciosArchive />
			<FAQAcordeon />
			<CTAFooter />
		</Layout>
	);
};

export default HomePage;
