import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import "../../assets/css/home-page.css";

const LogosClientes = () => {
	return (
		<>
			<section className="filatrescol pagina-peq">
				<div className="trescolumnas">
					<StaticImage
						src="../../assets/images/Aliados/LOGO-FINAL-CECAP.png"
						alt="Logo CECAP"
						layout="constrained"
						className="img"
					/>
				</div>
				<div className="trescolumnas">
					<StaticImage
						src="../../assets/images/Aliados/Logo-Nace-un-Emprendedor-FINAL.png"
						alt="Logo Nace un Emprededor"
						layout="constrained"
						className="img"
					/>
				</div>
				<div className="trescolumnas">
					<StaticImage
						src="../../assets/images/Aliados/Logo-Programa-Buenos-Negocios.png"
						alt="Logo Programa Buenos Negocios"
						layout="constrained"
						className="img"
					/>
				</div>
			</section>
		</>
	);
};

export default LogosClientes;
